<template>
    <div>
        <div class="w-90 center mt4 mini-spacing" style="background-color: #FBFCFE;">
            <div class="flex flex-wrap justify-between pa3" style=" font-size: 18px">
                <div class="font-w2 pb3 pb0-l" style="color: #132c8c;">Work In Progress</div>
            </div>

            <div v-for="(data, index) in state.rawMaterials" :key="index" class="pa3 flex flex-wrap items-center justify-between">
                <div class="flex flex-column w-30-l w-50 pb3 pb0-l ">
                    <label class="pb2">Name</label>
                    <v-select
                        label="name"
                        :filterable="false"
                        :options="state.options"
                        @search="onSearchRawMaterial"
                        v-model="data.name"
                        @option:selected="item => selectRawMaterial(data, item)"
                        @option:deselected="item => deselectRawMaterial(item)"
                    >
                        <template #open-indicator="{ attributes }">
                            <span v-bind="attributes"></span>
                        </template>
                        <template #no-options="{ search, searching, loading }">
                            Type to search raw material..
                        </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="flex flex-column w-30-l pl2 pl0-l w-50 pb3 pb0-l ">
                    <label class="pb2">Unit Cost</label>
                    <CurrencyInput
                        @input="calculateRawMaterialsTotal(index)"
                        class="product-input"
                        v-model="data.price"
                        :options="numberFormat"
                        placeholder="0"
                        required
                    />
                </div>
                <div class=" flex flex-column w-10-l w-50  pb3 pb0-l">
                    <label class="pb2">QTY</label>
                    <CurrencyInput
                        @input="calculateRawMaterialsTotal(index)"
                        class="product-input"
                        v-model="data.quantity"
                        :options="numberFormat"
                        placeholder="0"
                        required
                    />
                </div>
                <div class=" flex flex-column w-20-l w-50 pl2 pl0-l pb3 pb0-l">
                    <label class="pb2">Total</label>
                    <input class="product-input" type="text" v-model="data.total" placeholder="Length" required disabled />
                </div>
                <div class="pt4" @click.prevent="removeRawMaterial(index)" style="cursor: pointer">
                    <img :src="require('@/assets/images/delete.svg')" alt="Delete" />
                </div>
            </div>

            <div class="tr b pa3">
                <div class="">
                    <a href="" @click.prevent="addMoreRawMaterials" style="color: #132c8c"> + Add more raw material </a>
                </div>
            </div>

            <div class="flex flex-column w-30-l w-50 pb3 pb0-l ">
                <label class="pb2">Receiving Staff</label>
                <v-select
                    label="name"
                    :options="members"
                    v-model="state.staff"
                />
            </div>

            <!-- creat transaction & cancel Transactions button -->
            <div class="flex justify-between">
                <div>
                    <router-link :to="{ name: 'RawMaterial' }">&larr; Back</router-link>
                </div>
                <div class="pa3 flex">
                    <router-link :to="{ name: 'RawMaterial' }">
                        <button class="btn2">Cancel</button>
                    </router-link>
                    <button class="ml2" @click="handleSubmit" :disabled="state.processing">
                        {{ !state.processing ? 'Submit' : 'Processing...' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import AppWrapper from '@/layout/AppWrapper'
import Swal from 'sweetalert2'
import CurrencyInput from '@/components/CurrencyInput'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
    name: 'CreateWorkInProgress',
    components: { AppWrapper, CurrencyInput, vSelect },

    data() {
        return {
            numberFormat: {
                currency: 'USD',
                currencyDisplay: 'hidden',
                hideCurrencySymbolOnFocus: true,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: true,
                autoDecimalDigits: false,
                useGrouping: true,
                accountingSign: true
            }
        }
    },

    setup(_, { emit }) {
        const store = useStore()
        const members = computed(() => store.state.Settings.members)

        const state = reactive({
            rawMaterials: [
                {
                    name: '',
                    product: '',
                    price: 0,
                    quantity: 1,
                    instock: '',
                    total: '',
                    sku: ''
                }
            ],
            staff: '',
            options: [],
            processing: false
        })

        const addMoreRawMaterials = () => {
            state?.rawMaterials.push({
                name: '',
                product: '',
                quantity: 1,
                instock: '',
                price: 0,
                total: '',
                sku: ''
            })
        }

        const onSearchRawMaterial = (search, loading) => {
            if (search.length) {
                loading(true)
                store.dispatch('Production/searchProduction', { query: search, type: 'ALL' }).then(data => {
                    state.options = data.products
                    loading(false)
                })
            }
        }

        const selectRawMaterial = (rawMaterial, item) => {
            rawMaterial.name = item.name
            rawMaterial.product = item._id
            rawMaterial.price = item?.purchase_avg || 0
            rawMaterial.instock = item?.qty_in_stock || 0
            rawMaterial.total = item.total
            rawMaterial.sku = item?.sku || ''
            calculateRawMaterialsTotal()
        }

        const deselectRawMaterial = (rawMaterial, item) => {
            rawMaterial.name = ''
            rawMaterial.quantity = 0
            rawMaterial.price = 0
            rawMaterial.instock = 0
            rawMaterial.total = ''
            rawMaterial.sku = ''
            calculateRawMaterialsTotal()
        }
        const removeRawMaterial = index => {
            state?.rawMaterials?.splice(index, 1)
            calculateRawMaterialsTotal()
        }

        const calculateRawMaterialsTotal = index => {
            state.rawMaterials[index].total = state.rawMaterials[index].quantity * state.rawMaterials[index].price
        }

        const handleSubmit = () => {
            state.processing = true
            if (!state.rawMaterials.length || state.rawMaterials[0].name === '') {
                Swal.fire(`Sorry, Select at least one raw material`, '', 'error')
                state.processing = false
                return
            }
            if (state.staff === '') {
                Swal.fire(`Sorry, Select a staff`, '', 'error')
                state.processing = false
                return
            }

            const { options, ...rest } = state
            store.dispatch('Production/createWorkInProgress', rest)
            state.processing = false
        }

        onMounted(() => {
            store.dispatch('Settings/getTeamMembers')
        })

        return {
            state,
            members,
            addMoreRawMaterials,
            onSearchRawMaterial,
            selectRawMaterial,
            deselectRawMaterial,
            removeRawMaterial,
            calculateRawMaterialsTotal,
            handleSubmit
        }
    }
}
</script>

<style>
.makeBlock {
    display: block;
}

.inputRems {
    width: 23rem;
}

@media screen and (max-width: 767px) {
    .inputRems {
        width: 12rem;
    }

    .flexrow {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 999px) {
    .inputRems {
        width: 12rem;
    }

    /* .flexrow {
        display: flex;
        flex-direction: column;
    } */
}

@media (min-width: 800px) and (max-width: 1024px) {
    .inputRems {
        width: 17.3rem;
    }
}
.vs__dropdown-toggle {
    padding: 6px;
}
</style>
